import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {pallete} from "app/styles";
import {makeStyles} from "tss-react/mui";
import {getAxiosAPI} from "app/utils/axiosApiRequests";
import {Formik} from "formik";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { SearchInput } from "../inputs";
import { TeamMembersView } from "views/private/management/user-accounts/TeamMembersView";

const upatefees_schema = Yup.object().shape({
  trade_fees: Yup.number()
    .required("Trade fees is required")
    .min(0, "Value must be at least 0%")
    // .max(100, "Value must be at most 100%")
    // .test(
    //   "is-increment",
    //   "Value must be in 0.5% increments",
    //   (value) => value % 0.5 === 0
    // )
    ,
  money_hub_fees: Yup.number()
    .required("Moneyhub fees is required")
    .min(0, "Value must be at least 0%")
    // .max(100, "Value must be at most 100%")
    // .test(
    //   "is-increment",
    //   "Value must be in 0.5% increments",
    //   (value) => value % 0.5 === 0
    // )
    ,
  crypto_hub_fees: Yup.number()
    .required("Cryptohub fees is required")
    .min(0, "Value must be at least 0%")
    // .max(100, "Value must be at most 100%")
    // .test(
    //   "is-increment",
    //   "Value must be in 0.5% increments",
    //   (value) => value % 0.5 === 0
    // )
    ,
  wallet_hub_fees: Yup.number()
    .required("Wallet hub fees is required")
    .min(0, "Value must be at least 0%")
    // .max(100, "Value must be at most 100%")
    // .test(
    //   "is-increment",
    //   "Value must be in 0.5% increments",
    //   (value) => value % 0.5 === 0
    // )
    ,
  flex_hub_fees: Yup.number()
    .required("Flex hub fees is required")
    .min(0, "Value must be at least 0%")
    // .max(100, "Value must be at most 100%")
    // .test(
    //   "is-increment",
    //   "Value must be in 0.5% increments",
    //   (value) => value % 0.5 === 0
    // )
    ,
  hidden_trade_fees: Yup.number()
    .required("Hidden trade fees is required")
    .min(0, "Value must be at least 0%")
    // .max(100, "Value must be at most 100%")
    // .test(
    //   "is-increment",
    //   "Value must be in 0.5% increments",
    //   (value) => value % 0.5 === 0
    // )
    ,
  hidden_money_hub_fees: Yup.number()
    .required("Hidden moneyhub fees is required")
    .min(0, "Value must be at least 0%")
    // .max(100, "Value must be at most 100%")
    // .test(
    //   "is-increment",
    //   "Value must be in 0.5% increments",
    //   (value) => value % 0.5 === 0
    // )
    ,
  hidden_crypto_hub_fees: Yup.number()
    .required("Hidden cryptohub fees is required")
    .min(0, "Value must be at least 0%")
    // .max(100, "Value must be at most 100%")
    // .test(
    //   "is-increment",
    //   "Value must be in 0.5% increments",
    //   (value) => value % 0.5 === 0
    // )
    ,
  hidden_flex_hub_fees: Yup.number()
    .required("Hidden flexhub fees is required")
    .min(0, "Value must be at least 0%")
    // .max(100, "Value must be at most 100%")
    // .test(
    //   "is-increment",
    //   "Value must be in 0.5% increments",
    //   (value) => value % 0.5 === 0
    // )
    ,
  hidden_wallet_hub_fees: Yup.number()
    .required("Hidden wallet hub fees is required")
    .min(0, "Value must be at least 0%")
    // .max(100, "Value must be at most 100%")
    // .test(
    //   "is-increment",
    //   "Value must be in 0.5% increments",
    //   (value) => value % 0.5 === 0
    // )
    ,
  settlement_fees: Yup.number()
    .required("Settlement fees is required")
    .min(0, "Value must be at least 0%")
    // .max(100, "Value must be at most 100%")
    // .test(
    //   "is-increment",
    //   "Value must be in 0.5% increments",
    //   (value) => value % 0.5 === 0
    // )
    ,
  withdraw_fees: Yup.number()
    .required("Withdraw fees is required")
    .min(0, "Value must be at least 0%")
    // .max(100, "Value must be at most 100%")
    // .test(
    //   "is-increment",
    //   "Value must be in 0.5% increments",
    //   (value) => value % 0.5 === 0
    // )
    ,
});

const passwrd_schema = Yup.object().shape({
  password: Yup.string()
    .required("New password is required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
      "Password must be 8-16 characters long, with at least one uppercase letter, one number, and one special character"
    ),
  confirm_password: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), ""], "Passwords must match"),
});

const fees_schema = Yup.object().shape({
  fees: Yup.number()
    .required("Fees is required")
    .min(0, "Value must be at least 0"),
  platform: Yup.string().required("Platform is required"),
});

type T_PasswordInputState = {
  password: boolean;
  confirm_password: boolean;
};

export const CustomModal = ({ modalState }: any) => {
  const { classes } = useStyles();
  const [userFeesDetails, setUserFeesDetails] = useState<any>({});
  const [showPassword, setShowPassword] = React.useState({
    password: false,
    confirm_password: false,
  });
  const handleClickShowPassword = (value: string) => {
    setShowPassword((pre: any) => ({ ...pre, [value]: !pre[value] }));
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const StyledButton = styled(Button)(({ theme }) => ({
    padding: "5px 10px",
    fontSize: "14px",
    textTransform: "none",
    borderRadius: "4px",
    width: "100%",
    color: pallete.primaryWhite,
    backgroundColor: pallete.primaryPurple,
  }));

  useEffect(() => {
    if (modalState.modalType === "updateFee") {
      getAccountFees();
    }
  }, [modalState.modalType]);

  const getAccountFees = async () => {
    await getAxiosAPI(`/user/fees/${modalState.user.id}`, true)
      .then((res: any) => {
        console.log(res.data.data);
        const in_percentage = {
          trade_fees: (res.data.data.trade_fees || 0),
          money_hub_fees: (res.data.data.money_hub_fees || 0),
          crypto_hub_fees: (res.data.data.crypto_hub_fees || 0),
          wallet_hub_fees: (res.data.data.wallet_hub_fees || 0),
          flex_hub_fees: (res.data.data.flex_hub_fees || 0),
          hidden_trade_fees: (res.data.data.hidden_trade_fees || 0),
          hidden_money_hub_fees:
            (res.data.data.hidden_money_hub_fees || 0),
          hidden_crypto_hub_fees:
            (res.data.data.hidden_crypto_hub_fees || 0),
          hidden_wallet_hub_fees:
            (res.data.data.hidden_wallet_hub_fees || 0),
          hidden_flex_hub_fees: (res.data.data.hidden_flex_hub_fees || 0),
          settlement_fees: (res.data.data.settlement_fees || 0),
          withdraw_fees: (res.data.data.withdraw_fees || 0),
        };
        setUserFeesDetails(in_percentage);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const UpdateFess = () => {
    return (
      <Formik
        initialValues={userFeesDetails}
        enableReinitialize={true}
        validationSchema={upatefees_schema}
        onSubmit={(values: any) => {
          console.log(values);
          modalState.handleClick(values);
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
        }: any) => (
          <Box>
            <Box>
              <Typography
                variant="h4"
                color="primary"
                className={classes.headinText}
              >
                {modalState.heading}
              </Typography>
              <Typography variant="h6" color="primary">
                {modalState.text}
              </Typography>
            </Box>
            <TextField
              label="Trade Fees"
              className={classes.inputs}
              autoComplete="off"
              value={values.trade_fees}
              onBlur={handleBlur("trade_fees")}
              onChange={handleChange("trade_fees")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            {touched.trade_fees && errors.trade_fees && (
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.errorMsg}
              >
                {errors.trade_fees}
              </Typography>
            )}
            <TextField
              label="Moneyhub Fees"
              className={classes.inputs}
              autoComplete="off"
              value={values.money_hub_fees}
              onBlur={handleBlur("money_hub_fees")}
              onChange={handleChange("money_hub_fees")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            {touched.money_hub_fees && errors.money_hub_fees && (
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.errorMsg}
              >
                {errors.money_hub_fees}
              </Typography>
            )}
            <TextField
              label="Cryptohub Fees"
              className={classes.inputs}
              autoComplete="off"
              value={values.crypto_hub_fees}
              onBlur={handleBlur("crypto_hub_fees")}
              onChange={handleChange("crypto_hub_fees")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            {touched.crypto_hub_fees && errors.crypto_hub_fees && (
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.errorMsg}
              >
                {errors.crypto_hub_fees}
              </Typography>
            )}
            <TextField
              label="Wallet Hub Fees"
              className={classes.inputs}
              autoComplete="off"
              value={values.wallet_hub_fees}
              onBlur={handleBlur("wallet_hub_fees")}
              onChange={handleChange("wallet_hub_fees")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            {touched.wallet_hub_fees && errors.wallet_hub_fees && (
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.errorMsg}
              >
                {errors.wallet_hub_fees}
              </Typography>
            )}
            <TextField
              label="Flexhub Fees"
              className={classes.inputs}
              autoComplete="off"
              value={values.flex_hub_fees}
              onBlur={handleBlur("flex_hub_fees")}
              onChange={handleChange("flex_hub_fees")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            {touched.flex_hub_fees && errors.flex_hub_fees && (
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.errorMsg}
              >
                {errors.flex_hub_fees}
              </Typography>
            )}
            <TextField
              label="Settlement Fees"
              className={classes.inputs}
              autoComplete="off"
              value={values.settlement_fees}
              onBlur={handleBlur("settlement_fees")}
              onChange={handleChange("settlement_fees")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            {touched.settlement_fees && errors.settlement_fees && (
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.errorMsg}
              >
                {errors.settlement_fees}
              </Typography>
            )}
            <TextField
              label="Withdraw Fees"
              className={classes.inputs}
              autoComplete="off"
              value={values.withdraw_fees}
              onBlur={handleBlur("withdraw_fees")}
              onChange={handleChange("withdraw_fees")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            {touched.withdraw_fees && errors.withdraw_fees && (
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.errorMsg}
              >
                {errors.withdraw_fees}
              </Typography>
            )}
            <TextField
              label="Hidden Trade Fees"
              className={classes.inputs}
              autoComplete="off"
              value={values.hidden_trade_fees}
              onBlur={handleBlur("hidden_trade_fees")}
              onChange={handleChange("hidden_trade_fees")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            {touched.hidden_trade_fees && errors.hidden_trade_fees && (
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.errorMsg}
              >
                {errors.hidden_trade_fees}
              </Typography>
            )}
            <TextField
              label="Hidden Moneyhub Fees"
              className={classes.inputs}
              autoComplete="off"
              value={values.hidden_money_hub_fees}
              onBlur={handleBlur("hidden_money_hub_fees")}
              onChange={handleChange("hidden_money_hub_fees")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            {touched.hidden_money_hub_fees && errors.hidden_money_hub_fees && (
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.errorMsg}
              >
                {errors.hidden_money_hub_fees}
              </Typography>
            )}
            <TextField
              label="Hidden Cryptohub Fees"
              className={classes.inputs}
              autoComplete="off"
              value={values.hidden_crypto_hub_fees}
              onBlur={handleBlur("hidden_crypto_hub_fees")}
              onChange={handleChange("hidden_crypto_hub_fees")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            {touched.hidden_crypto_hub_fees &&
              errors.hidden_crypto_hub_fees && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.hidden_crypto_hub_fees}
                </Typography>
              )}
            <TextField
              label="Hidden Wallet Hub Fees"
              className={classes.inputs}
              autoComplete="off"
              value={values.hidden_wallet_hub_fees}
              onBlur={handleBlur("hidden_wallet_hub_fees")}
              onChange={handleChange("hidden_wallet_hub_fees")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            {touched.hidden_wallet_hub_fees &&
              errors.hidden_wallet_hub_fees && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.hidden_wallet_hub_fees}
                </Typography>
              )}
            <TextField
              label="Hidden Flexhub Fees"
              className={classes.inputs}
              autoComplete="off"
              value={values.hidden_flex_hub_fees}
              onBlur={handleBlur("hidden_flex_hub_fees")}
              onChange={handleChange("hidden_flex_hub_fees")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            {touched.hidden_flex_hub_fees && errors.hidden_flex_hub_fees && (
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.errorMsg}
              >
                {errors.hidden_flex_hub_fees}
              </Typography>
            )}
            
            <Box>
              <DialogActions>
                <StyledButton
                  variant="contained"
                  sx={{ backgroundColor: "grey" }}
                  onClick={modalState.handleClose}
                  autoFocus
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  Yes
                </StyledButton>
              </DialogActions>
            </Box>
          </Box>
        )}
      </Formik>
    );
  };

  const UpdatePassword = () => {
    return (
      <Formik
        initialValues={{
          password: "",
          confirm_password: "",
        }}
        validationSchema={passwrd_schema}
        onSubmit={(values: any) => {
          console.log(values);
          modalState.handleClick(values);
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
        }: any) => (
          <Box>
            <Box>
              <Typography
                variant="h4"
                color="primary"
                className={classes.headinText}
              >
                {modalState.heading}
              </Typography>
              <Typography variant="h6" color="primary">
                {modalState.text}
              </Typography>
            </Box>
            <FormControl className={classes.inputs} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                label="Password"
                id="outlined-adornment-password"
                type={showPassword.password ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("password")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword.password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                autoComplete="off"
                value={values.password}
                onBlur={handleBlur("password")}
                onChange={handleChange("password")}
              />
              {touched.password && errors.password && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.password}
                </Typography>
              )}
            </FormControl>
            <FormControl className={classes.inputs} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                label="Confirm Password"
                id="outlined-adornment-password"
                type={showPassword.confirm_password ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword("confirm_password")
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword.confirm_password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                autoComplete="off"
                value={values.confirm_password}
                onBlur={handleBlur("confirm_password")}
                onChange={handleChange("confirm_password")}
              />
              {touched.confirm_password && errors.confirm_password && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.confirm_password}
                </Typography>
              )}
            </FormControl>
            <Box>
              <DialogActions>
                <StyledButton
                  variant="contained"
                  sx={{ backgroundColor: "grey" }}
                  onClick={modalState.handleClose}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  Yes
                </StyledButton>
              </DialogActions>
            </Box>
          </Box>
        )}
      </Formik>
    );
  };

  const UserVerfications = () => {
    return (
      <Box>
        <DialogTitle id="alert-dialog-title">{modalState.heading}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalState.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={modalState.handleClose}>Disagree</Button>
          <Button onClick={modalState.handleClick} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Box>
    );
  };

  const Reset2FA = () => {
    return (
      <Box>
        <Box>
          <Typography
            variant="h4"
            color="primary"
            className={classes.headinText}
          >
            {modalState.heading}
          </Typography>
          <Typography variant="h6" color="primary">
            {modalState.text}
          </Typography>
        </Box>
        <Box>
          <DialogActions>
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: "grey" }}
              onClick={modalState.handleClose}
              autoFocus
            >
              Cancel
            </StyledButton>
            <StyledButton variant="contained" onClick={modalState.handleClick}>
              Yes
            </StyledButton>
          </DialogActions>
        </Box>
      </Box>
    );
  };

  const SettleCryptoPayment = () => {
    const platforms = [
      { id: "crypto", label: "Cryptohub" },
      { id: "flex", label: "Flexhub" },
    ];
    return (
      <Formik
        initialValues={{
          fees: "",
          platform: "cryptohub",
        }}
        validationSchema={fees_schema}
        onSubmit={(values: any) => {
          modalState.handleClick(values);
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          values,
        }: any) => (
          <Box>
            <Box>
              <Typography
                variant="h4"
                color="primary"
                className={classes.headinText}
              >
                {modalState.heading}
              </Typography>
              <Typography variant="h6" color="primary">
                {modalState.text}
              </Typography>
            </Box>
            <FormControl className={classes.inputs} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-fees">
                Fees in EURO
              </InputLabel>
              <OutlinedInput
                label="Fees in EURO"
                id="outlined-adornment-fees"
                type="number"
                autoComplete="off"
                value={values.fees}
                onBlur={handleBlur("fees")}
                onChange={handleChange("fees")}
              />
              {touched.fees && errors.fees && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.fees}
                </Typography>
              )}
            </FormControl>
            <SearchInput
                options={platforms}
                label={"Select Platform"}
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="label"
                formikValue="platform"
              />
            {touched.platform && errors.platform && (
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.errorMsg}
              >
                {errors.platform}
              </Typography>
            )}
            <Box>
              <DialogActions>
                <Button onClick={modalState.handleClose}>Disagree</Button>
                <Button onClick={() => handleSubmit()} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Box>
          </Box>
        )}
      </Formik>
    );
  };

  const TeamMembers = () => {
    return (
      <Box>
        <DialogTitle id="alert-dialog-title">{modalState.heading}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalState.text}
          </DialogContentText>
          <TeamMembersView userId={modalState.user.id}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={modalState.handleClose}>
            Close
          </Button>
        </DialogActions>
      </Box>
    );
  }

  const renderForm = () => {
    if (modalState.modalType === "verification") {
      return <UserVerfications />;
    } else if (modalState.modalType === "updateFee") {
      return <UpdateFess />;
    } else if (modalState.modalType === "updatePassword") {
      return <UpdatePassword />;
    } else if (modalState.modalType === "reset2FA") {
      return <Reset2FA />;
    } else if (modalState.modalType === "settleCryptohubPayment") {
      return <SettleCryptoPayment />;
    } else if (modalState.modalType === "teamMembers") {
      return <TeamMembers />;
    }
  };

  return (
    <Dialog
      open={modalState.isOpen}
      onClose={modalState.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...(modalState.modalType === "teamMembers" ? {fullWidth: true, maxWidth: "xl"} : {})}
    >
      <Box 
        className={modalState.modalType === "teamMembers" ? classes.modalBoxFullWidth : classes.modalBox}
      >
          {renderForm()}
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    inputs: {
      margin: "10px 0",
      width: "100%",
    },
    modalBox: {
      width: "400px",
      padding: "20px 10px",
    },
    modalBoxFullWidth: {
      width: "100%",
      padding: "20px 10px",
    },
    headinText: {
      paddingBottom: "10px",
    },
  };
});

import {RQ} from "app/hooks";
import {ApiConfig} from "app/config";

export const FLEXHUB_APIS = {
    USER: {
        CRUD: ApiConfig.create("flex-hub-user"),
        TRANSACTION: ApiConfig.create("flex-hub-user"),
    },
    TRANSACTION: {
        COMPLETE: ApiConfig.create("flex-hub-transaction/complete-payment"),
    },
    TRANSACTION_REQUEST:{
        SEARCH:ApiConfig.create('flex-hub-transaction-request/search'),
        TODAY: ApiConfig.create('user/flex-hub-transaction-request'),
    }
}

export type T_CHUserAccount = {
    id: string;
    full_name: string;
    email: string;
    dob: string;
    mobile: string | null;
    is_registered: boolean;
    kyc_is_verified: boolean;
    kyc_is_in_progress: boolean;
    kyc_is_completed: boolean;
    email_verified_at: string;
};

export const useUseFlexHubUserAccountsListQuery = (): RQ.T_QueryResult<T_CHUserAccount[]> => {
    return RQ.useQueryRequest<T_CHUserAccount[]>({
        url: FLEXHUB_APIS.USER.CRUD.url,
        queryKey: FLEXHUB_APIS.USER.CRUD.key,
    });
}

export type T_CHCompleteTranReqParams = {};
type T_CHCompleteTranReqResult = RQ.T_QueryResult<{}>;
export const useCompleteFlexhubTransactionRequest = (opt: T_CHCompleteTranReqParams): T_CHCompleteTranReqResult => {
    return RQ.useQueryRequest<T_CHUserAccount[]>({
        url: FLEXHUB_APIS.TRANSACTION.COMPLETE.url,
        queryKey: FLEXHUB_APIS.TRANSACTION.COMPLETE.key,
    });
}


export type T_FlexhubOrder = {
    id: string;
    sender_id: number;
    receiver_id: number;
    sender_email: string;
    receiver_email: string;
    user_id_in_merchant_site: string;
    currency_id: number;
    currency_symbol: string;
    language_id: number;
    language_name: string;
    language_symbol: string;
    verified_at: string;
    asset_id: number;
    fireblocks_asset_id: string;
    fireblocks_wallet_address: string;
    amount: number;
    fee: number;
    network_fee: number;
    status_id: number;
    status_name: string;
    received_quantity: number;
    payment_id: string;
};

type T_FlexhubOrderList = T_FlexhubOrder[];
export const useFlexhubOrdersSearchQuery = (opt?: RQ.T_UseSearchQueryParams): RQ.T_UseSearchQueryResult<T_FlexhubOrderList> => {
    return RQ.useSearchQuery<T_FlexhubOrderList>({
        url: FLEXHUB_APIS.TRANSACTION_REQUEST.SEARCH.url,
        queryKey: FLEXHUB_APIS.TRANSACTION_REQUEST.SEARCH.key,
        searchParams: opt?.searchParams || {}
    })
}


export const useFlexhubOrdersToday = (user_id: string, payload: Object) => {
  return RQ.useQueryRequest<T_FlexhubOrderList>({
    url: FLEXHUB_APIS.TRANSACTION_REQUEST.TODAY.url + `/${user_id}`,
    payload: payload,
    queryKey: [...FLEXHUB_APIS.TRANSACTION_REQUEST.TODAY.key, user_id],
    method: "POST",
  });
};
import {ApiConfig} from "app/config";
import {RQ} from "app/hooks";

const FLEX_PAYMENT_APIS = {
    TRANSACTION_REQUEST: ApiConfig.create('flex-hub-transaction-request'),
    VERIFY_TRANSACTION: ApiConfig.create('flex-hub-transaction'),
    COMPLETE_TRANSACTION: ApiConfig.create('flex-hub-transaction'),
}
        
export type T_FlexhubTransactionRequest = {
    id: string;
    sender_id: number;
    receiver_id: number;
    sender_email: string;
    receiver_email: string;
    user_id_in_merchant_site: string;
    currency_id: number;
    currency_symbol: string;
    language_id: number;
    language_name: string;
    language_symbol: string;
    asset_id: number;
    fireblocks_asset_id: string;
    verified_at: string | null;
    fireblocks_wallet_address: string;
    asset_quantity: number;
    fee_quantity: number;
    received_quantity: number;
    status_id: number;
    status_name: string;
}

export const useFlexhubTransactionRequest = (transactionId: string): RQ.T_QueryResult<T_FlexhubTransactionRequest> => {
    return RQ.useQueryRequest<T_FlexhubTransactionRequest>({
        url: FLEX_PAYMENT_APIS.TRANSACTION_REQUEST.url,
        queryKey: FLEX_PAYMENT_APIS.TRANSACTION_REQUEST.key,
        params: [transactionId],
        refetchOnWindowFocus: false,
    })
}

export const useFlexhubTransactionVerify = (transactionId: string): RQ.T_QueryResult<any> => {
    return RQ.useQueryRequest<any>({
        url: FLEX_PAYMENT_APIS.VERIFY_TRANSACTION.url,
        queryKey: [...FLEX_PAYMENT_APIS.VERIFY_TRANSACTION.key, transactionId],
        params: [transactionId, 'verify-payment'],
        refetchOnWindowFocus: false,
        refetchInterval: 10000,
        method: 'POST',
    })
}

export const useFlexhubTransactionComplete = (transactionId: string): RQ.T_MutationResult<any> => {
    return RQ.usePostRequest<any>({
        url: FLEX_PAYMENT_APIS.COMPLETE_TRANSACTION.url + '/' + transactionId + '/complete-payment',
        mutationKey: [...FLEX_PAYMENT_APIS.COMPLETE_TRANSACTION.key, '/complete-payment'],
    })
}

export const useFlexhubTransactionAccept = (transactionId: string): RQ.T_MutationResult<any> => {
    return RQ.usePostRequest<any>({
        url: FLEX_PAYMENT_APIS.COMPLETE_TRANSACTION.url + '/' + transactionId + '/accept-settlement',
        mutationKey: [...FLEX_PAYMENT_APIS.COMPLETE_TRANSACTION.key, '/accept-settlement'],
    })
}
import React from "react";
import { FlexHubOrders } from "./FlexHubOrders";
import { TabContent } from "app/components/tabs";
import { FlexHubDailyOrders } from "./FlexHubDailyOrders";

type T_FlexHubIdState = {
  userId?: string;
  merchantId?: string;
};
export const FlexHubOrdersView = (): React.JSX.Element => {
  // const [flexHubIdState, setFlexHubIdState] = useState<T_FlexHubIdState>({});
  //
  // const setFlexHubIdFn = ({userId, merchantId}: Partial<T_FlexHubIdState>) => {
  //     setFlexHubIdState({userId, merchantId});
  // }

  // if (flexHubIdState.userId) {
  //     return <FlexHubUserOrders userId={flexHubIdState.userId} setFlexHubIdFn={setFlexHubIdFn} />;
  // }
  //
  // if (flexHubIdState.merchantId) {
  //     return <FlexHubMerchantOrders merchantId={flexHubIdState.merchantId} setFlexHubIdFn={setFlexHubIdFn} />;
  // }
  const tabHeading: string = "";
  const tabData = [
      {label: "All", component: <FlexHubOrders />},
      {label: "Today", component: <FlexHubDailyOrders />},
  ];
  return TabContent({tabHeading, tabData});
};

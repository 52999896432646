import {RQ} from "app/hooks";
import {ApiConfig} from "app/config";
import { use } from "i18next";


export type T_TeamMember = {
    last_login: string | null;
    id: string;
    email: string;
    role_name: string;
    first_name: string;
    full_name: string;
    last_name: string | null;
    mobile_no: string | null;
    dob: string;
    image: string | null;
    auth_type: number;
    auth_group_id: number;
    auth_uuid: string | null;
    kyc_auth_id: string | null;
    kyc_is_verified: boolean;
    kyc_result: string | null;
    mfa_secret: string;
    email_verified_at: string | null;
    company_id: number;
    country: string | null;
    is_2fa_active: boolean;
    trade_fees: number | null;
    money_hub_fees: number | null;
    crypto_hub_fees: number | null;
}

export type T_TeamMemberList = T_TeamMember[];

const apiConfig = ApiConfig.create("user-account/team-member");

export const useTeamMembersQuery = (userId: String): RQ.T_QueryResult<T_TeamMemberList> => {
    return RQ.useQueryRequest<T_TeamMemberList>({
        url: apiConfig.url, 
        queryKey: apiConfig.key, 
        enabled: userId !== "",
        params: [userId],
    });
}

